#page-hospileg img {
  max-width: 100%;
  line-height: 1;
  vertical-align: bottom;
  display: block;
}

@media screen and (min-width: 1001px) {
  #page-hospileg a:hover {
    opacity: .9;
  }
}

#page-hospileg .contents-inner {
  max-width: 1000px;
  margin: 0 auto;
}

#page-hospileg .subscription {
  position: relative;
  background: #fff;
  padding: 140px 0 60px 0;
}

#page-hospileg .subscription.style-bg {
  background: #f0e5f1;
}

#page-hospileg .subscription a {
  display: block;
}

#page-hospileg .subscription__ribon {
  width: 409px;
  position: absolute;
  left: 30px;
  top: -22px;
  pointer-events: none;
}

#page-hospileg .subscription__before {
  width: 409px;
  position: absolute;
  right: 130px;
  top: 60px;
  pointer-events: none;
}

#page-hospileg .subscription__btn {
  width: 959px;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .subscription {
    padding: 14vw 0 6vw 0;
  }
  #page-hospileg .subscription__ribon {
    width: 40.9vw;
    position: absolute;
    left: 3vw;
    top: -2.2vw;
    pointer-events: none;
  }
  #page-hospileg .subscription__before {
    width: 40.9vw;
    position: absolute;
    right: 13vw;
    top: 6vw;
    pointer-events: none;
  }
  #page-hospileg .subscription__btn {
    width: 95.9vw;
    margin: 0 auto;
  }
}

#page-hospileg .henkin {
  padding-bottom: 80px;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .henkin {
    padding-bottom: 8vw;
  }
}

#page-hospileg .henkin__detail {
  max-width: 860px;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .henkin__detail {
    width: 86vw;
  }
}

#page-hospileg .henkin__detail dt {
  position: relative;
}

#page-hospileg .henkin__detail dt:before {
  position: absolute;
  content: '';
  top: 50%;
  right: 40px;
  height: 2px;
  width: 40px;
  background: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .henkin__detail dt:before {
    right: 4vw;
    height: 0.2vw;
    width: 4vw;
  }
}

#page-hospileg .henkin__detail dt:after {
  position: absolute;
  content: '';
  top: 50%;
  right: 40px;
  height: 2px;
  width: 40px;
  background: #fff;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .henkin__detail dt:after {
    right: 4vw;
    height: 0.2vw;
    width: 4vw;
  }
}

#page-hospileg .henkin__detail dt.open:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

#page-hospileg .henkin__detail dt.open:after {
  opacity: 0;
}

#page-hospileg .henkin__detail dd.accordion-text {
  display: none;
}

#page-hospileg .henkin__detail dd ul {
  padding-top: 20px;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .henkin__detail dd ul {
    padding-top: 2vw;
  }
}

#page-hospileg .henkin__detail dd ul li {
  font-size: 24px;
  line-height: 150%;
  -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
  color: #505050;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .henkin__detail dd ul li {
    font-size: 3.6vw;
  }
}

#page-hospileg .cv {
  position: relative;
}

#page-hospileg .cv__btn {
  width: 867px;
  position: absolute;
  left: 50%;
  bottom: 120px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

#page-hospileg .cv__btn__ribon {
  width: 271px;
  position: absolute;
  left: -10px;
  top: -30px;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .cv__btn {
    width: 86.7vw;
    bottom: 12vw;
  }
  #page-hospileg .cv__btn__ribon {
    width: 27.1vw;
    left: -1vw;
    top: -3vw;
  }
}

#page-hospileg .cv__regular {
  width: 82%;
  max-width: 820px;
  margin: 60px auto;
}

@media screen and (max-width: 1000px) {
  #page-hospileg .cv__regular {
    margin: 6vw auto;
  }
}

#page-hospileg .tokuten__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#page-hospileg .tokuten__flex__item {
  width: 50%;
}
